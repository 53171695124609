import Vue from "vue";
import { Banorte } from "apd.models";
import { AxiosHttpClient, ApiErrorResponse, ApiBancos } from "apd.apiconnectors";
import { ApiList, getUrl } from "@/config/apiUrls";

export default Vue.extend({
  name: "VisorBanorteComponent",
  components: {
    Banorte: () => import("@/components/banorte/banorte.vue"),
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      reqs3DS: [] as Banorte.Payworks2.Request3DS[],
      reqsWeb: [] as Banorte.Payworks2.RequestWeb[],
      reqsPinpad: [] as Banorte.Payworks2.RequestPinPad[],
      err3ds: {} as ApiErrorResponse,
      errWeb: {} as ApiErrorResponse,
      errPinpad: {} as ApiErrorResponse,
      icons: {
        banorte: this.$vuetify.theme.dark ? "$vuetify.icons.banorteDark" : "$vuetify.icons.banorte",
      } as Record<string, string>,
      loading: true as boolean,
    };
  },
  async created() {
    const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(getUrl(ApiList.Bancos, this.$store.getters.appMode));
    axiosHttpClient.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiBancos = new ApiBancos(axiosHttpClient);
    this.loading = true;

    //const tasks = [
    apiSP
      .GetRequest3DsOperacion(this.id)
      .then((data) => {
        this.reqs3DS = data as Banorte.Payworks2.Request3DS[];
      })
      .catch((err) => {
        this.err3ds = err as ApiErrorResponse;
      }),
      apiSP
        .GetRequestWebOperacion(this.id)
        .then((data) => {
          this.reqsWeb = data as Banorte.Payworks2.RequestWeb[];
        })
        .catch((err) => {
          this.errWeb = err as ApiErrorResponse;
        }),
      apiSP
        .GetRequestPinPadOperacion(this.id)
        .then((data) => {
          this.reqsPinpad = data as Banorte.Payworks2.RequestPinPad[];
        })
        .catch((err) => {
          this.errPinpad = err as ApiErrorResponse;
        }),
      //];
      //await Promise.all(tasks);
      (this.loading = false);
  },
});
